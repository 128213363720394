<template>
  <div>

    <!-- Filter -->
    <div class="row justify-content-end mt-n3">
      <div class="col-md-3 col-12">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Nama Kategori"
            @keyup="filterByName"></b-form-input>
        </b-input-group>
      </div>
    </div>

    <b-table
      striped
      hover
      responsive
      class="mt-3"
      style="white-space: nowrap"
      :items="items"
      :fields="fields">
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '15%' : '' }" />
      </template>
      <template #cell(actions)="data">
        <template v-if="purpose == 'master'">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Detail"
            @click="$router.push({ path: '/expense-categories/detail/' + data.item.id })"><i class="fas fa-eye px-0"></i></b-button>
          <b-button
            size="sm"
            class="mr-1 btn-success"
            v-b-tooltip.hover
            title="Edit"
            v-b-modal.modal-form
            v-if="manipulateBtn == true"
            @click="btnEditOnClick(data.item.id)"><i class="fas fa-edit px-0"></i></b-button>
          <b-button
            size="sm"
            class="btn-danger"
            v-b-tooltip.hover
            title="Hapus"
            v-if="manipulateBtn == true"
            @click="btnDeleteOnClick(data.item.id)"><i class="fas fa-trash px-0"></i></b-button>
        </template>
        <template v-if="purpose == 'modal'">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            @click="chooseCategory({ id: data.item.id, name: data.item.name })"> Pilih </b-button>
        </template>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"></b-pagination>

  </div>
</template>

<script>

import { debounce } from '@/core/modules/Helper.js'
import module from '@/core/modules/CrudModule.js'

export default {

  props: {
    purpose: String,
  },

  data() {
    return {
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Filter
      filter: {
        name: ''
      },
      // Table Fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "description",
          label: "Deskripsi",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      // access management
      manipulateBtn: false,
    }
  },

  methods: {

    async pagination() {
      let filterParams = `&name=${this.filter.name}`
      let response = await module.paginate('expense-categories', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.totalRows = pagination.total

      let a, b
      b = this.perPage * (this.currentPage - 1) + 1
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a
      }

      this.items = response.data
    },

    pageOnClick(evt, page) {
      this.currentPage = page
      this.pagination()
    },

    filterByName() {
      debounce(() => { this.pagination() }, 500)
    },

    btnEditOnClick(id) {
      this.$emit('btnEditOnClick', id)
    },

    btnDeleteOnClick(id) {
      this.$emit('btnDeleteOnClick', id)
    },

    chooseCategory(value) {
      this.$emit('chooseCategory', value)
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      let a
      for (a = 0; a < access_right.length; a++) {
        console.log("looping")
        if (access_right[a] == "6007") {
          this.manipulateBtn = true
        }
      }
    },

  },

  mounted() {
    this.pagination()
    this.setActiveMenu()
  }

}
</script>

<style></style>